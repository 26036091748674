import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  /* plugin style override */
  span {
    display: block !important;
    width: 100%;
    height: 100%;
  }
  /* plugin style override */
  img {
    object-fit: cover;
    object-position: center center;
    transition: transform 0.3s linear, opacity 0.3s !important;
  }
`

const imagePlaceholderQuery = graphql`
  query ImagePlaceholder {
    file(relativePath: { eq: "image-placeholder.png" }) {
      childImageSharp {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
  }
`

const Image = ({ src, alt, isThumbnail, isLightbox, ...delegation }) => {
  const { file } = useStaticQuery(imagePlaceholderQuery)
  const size = useWindowSize()

  const getImageSize = () => {
    if (isThumbnail || size.width <= theme.breakpoints.medium) return src.medium
    if (isLightbox) return src.original
    return src.large
  }

  const renderImage = () => {
    if (src && src.small && src.large) {
      return (
        <>
          <LazyLoadImage
            alt={alt}
            src={getImageSize()} // use normal <img> attributes as props
            placeholderSrc={src.small}
            effect="blur"
            width="100%"
            height="100%"
            threshold={200}
            {...delegation}
          />
        </>
      )
    }
    if (src && !src.large) {
      return (
        <>
          <LazyLoadImage
            alt={alt}
            src={src}
            placeholderSrc={file.childImageSharp.fluid.src}
            effect="blur"
            width="100%"
            height="100%"
            threshold={200}
            {...delegation}
          />
        </>
      )
    }
    return <Img fluid={file.childImageSharp.fluid} {...delegation} />
  }

  return (
    <ImageWrapper className="image-wrapper" {...delegation}>
      {renderImage()}
    </ImageWrapper>
  )
}

Image.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.shape({
      original: PropTypes.string,
      large: PropTypes.string,
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  isThumbnail: PropTypes.bool,
  isLightbox: PropTypes.bool,
}

Image.defaultProps = {
  isThumbnail: false,
  isLightbox: false,
}

export default Image
